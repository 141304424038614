.aboutSection2 {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 5% 0;
}

.magazineDescription {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.aboutSection2 .magazineDescription p {
    text-align: left;
    font-weight: 500;
    font-size: 100%;
    color: #747d88;
    line-height: 1.8em;
    padding-bottom: 1em;
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
    word-wrap: break-word;
    margin: 0;
}

.magazine {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    padding: 10%;
}

.couvertureMagazine, .articleMagazine {
    width: 35%;
    height: auto;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
}

.couvertureMagazine {
    margin-right: 10%;
}

@media (max-width: 768px) {
    .magazineDescription {
        max-width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .magazine {
        max-width: 100%;
        flex-direction: column;
        align-items: center;
        padding: 10%;
    }

    .couvertureMagazine, .articleMagazine {
        width: 100%;
    }

    .couvertureMagazine {
        margin-bottom: 10%;
        margin-right: 0;
    }
}

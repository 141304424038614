.imagePrestationsHeaderContainer {
    background-image: url('../../../assets/texture/background-texture.jpeg');
    background-size: cover;
    background-position: center;
    padding: 4% 0;
    width: 100vw;
    display: flex;
    justify-content: center;
}

.PrestationsHeaderContainer {
    padding: 2% 0;
    width: 80%;
    display: flex;
    justify-content: center;
}

.PrestationsHeader {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.PrestationsHeader h1 {
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration-style: dotted;
    font-size: 60px;
    color: #353740;
    line-height: 1.3em;
    padding-bottom: 10px;
    margin: 0 0 2.75% 0;
    text-align: left;
    word-wrap: break-word;
}

.separatorContainer {
    line-height: 1.7em;
    height: 23px;
    max-width: 120px;
    width: 100%;
}

.separator {
    background-color: #353740;
    height: 6px;
    width: 100%;
}

@media (max-width: 980px) {
    .separatorContainer {
        display: none;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

.section1Container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 110px 0;
}

.section1 {
    width: 80%;
    max-width: 1080px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.titleContainer {
    margin-bottom: 40px;
    padding: 27px 0;
}

.titleContainer h2 {
    margin: 0 0 2.75% 0;
    padding-bottom: 10px;
    font-family: 'Pinyon Script',cursive,'Montserrat',Helvetica,Arial,Lucida,sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 42px;
    line-height: 1.3em;
}

.titleContainer p {
    color: #747d88;
    font-size: 16px;
    font-weight: 500;
    margin: 0 auto;
    text-align: center;
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
    line-height: 1.8em;
}

/* ----------------------------- */

.itemsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 25px 0;
    margin: auto;
}

.itemsContainer a {
    text-decoration: none;
}

.items {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.item {
    margin-right: 3%;
    width: 33.33%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.itemLast {
    margin-right: 0;
}

.imgContainer {
    max-width: 60px;
    max-height: 60px;
    margin-bottom: 30px;
}

.imgContainer img,
.imgContainer img {
    width: 100%;
}

.item h3 {
    margin: 0;
    padding-bottom: 10px;
    word-wrap: break-word;
    text-align: center;
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
    font-weight: 700;
    font-size: 22px;
    color: #353740;
    line-height: 1.4em;
}

.item p {
    margin: 0;
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
    font-size: 100%;
    font-weight: 500;
    color: #747d88;
    line-height: 1.8em;
}

@media (max-width: 981px) {
    .itemsContainer {
        justify-content: center;
    }

    .itemsContainer1 {
        padding-bottom: 0;
    }

    .itemsContainer2 {
        padding-top: 0;
    }

    .items {
        flex-direction: column;
        align-items: center;
    }

    .item {
        margin-bottom: 30px;
        width: 100%;
    }

    .itemLast {
        margin-bottom: 30px;
        width: 100%;
    }
}

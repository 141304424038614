/* .map {
    width: 100%;
    height: 500px;
} */

iframe {
    width: 100%;
    height: 500px;
    border: none;
}

.Section3Container {
    width: 100%;
    padding-top: 109px;
    padding-bottom: 110px;
    display: flex;
    justify-content: center;
}

.Section3 {
    width: 80%;
    max-width: 1080px;
    padding: 40px 0;
    display: flex;
    justify-content: space-between;
}

.imagesContainerSection3 {
    width: 54%;
    display: flex;
    align-items: center;
    margin-right: 8%;
}

.imagesContainerSection3 img {
    width: 100%;
    height: auto;
}

.textContainerSection3 {
    width: 46%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 7% 0;
}

.textContainerSection3 h3 {
    color: #353740;
    padding-bottom: 10px;
    margin: 0 0 8.696% 0;
    font-family: 'Pinyon Script',cursive,'Montserrat',Helvetica,Arial,Lucida,sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 42px;
    line-height: 1.3em;
    text-align: left;
    /* white-space: nowrap;
    word-wrap: break-word; */
}

.textContainerSection3 p {
    margin: 0 0 8.696% 0;
    padding-bottom: 20px;
    text-align: left;
    color: #747d88;
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
    font-size: 100%;
    font-weight: 500;
    line-height: 1.8em;
    max-width: 700px;
    word-wrap: break-word;
}

.buttonContainerSection3 {
    display: flex;
    justify-content: flex-start;
}

.buttonContainerSection3 a {
    color: #747d88;
    border-width: 4px!important;
    border-color: #747d88;
    border-radius: 100px;
    letter-spacing: 2px;
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;
    font-size: 20px;
    padding: 0.3em 1em;
    line-height: 1.7em;
    background-color: transparent;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    border: 2px solid;
    text-decoration: none;
}

@media (max-width: 980px) {
    .Section3 {
        flex-direction: column;
        align-items: center;
    }

    .imagesContainerSection3 {
        width: 100%;
        margin: 0;
    }

    .textContainerSection3 {
        width: 100%;
        margin: 0;
        padding: 7% 0;
    }
}
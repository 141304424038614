.ContactSection1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5% 0;
    width: 100%;
}

.addressItemContainer {
    width: 100%;
    display: flex;
}

.addressItem {
    display: flex;
    flex-direction: column;
    padding: 2% 0;
    width: 50%;
}

.addressItem h2 {
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
    font-weight: 700;
    font-size: 22px;
    color: #353740;
    line-height: 1.9em;
    padding-bottom: 10px;
    text-align: left;
    word-wrap: break-word;
    margin: 0;
}

.addressItem p {
    text-align: left;
    font-weight: 500;
    font-size: 100%;
    color: #747d88;
    line-height: 1.8em;
    padding-bottom: 1em;
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
    word-wrap: break-word;
    margin: 0;
}

.email a {
    text-decoration: underline;
    text-decoration-style: double;
    text-decoration-color: rgb(87, 157, 255);
    color: #353740;
}

.addressItem2 {
    width: 50%;
    text-align: end;
}

.ContactSection1 .ContactSection1Description {
    width: 100%;
    padding: 2% 0;
}

.ContactSection1 .ContactSection1Description p {
    font-weight: 500;
    font-size: 100%;
    color: #747d88;
    line-height: 1.8em;
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
    word-wrap: break-word;
    margin: 20px auto;
    text-align: center;
}

@media (max-width: 980px) {
    .Contact {
        align-items: flex-start;
    }

    .addressItems {
        flex-direction: column;
        align-items: flex-start;
    }

    .addressItem {
        width: 100%;
        margin-bottom: 30px;
    }

    .addressItem3 {
        margin-bottom: 0;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

/* ------------------- Section 1 ---------------------- */

.homeHeader {
    display: flex;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 84px);
}

.titlesContainer {
    display: flex;
    width: 50%;
    background-color: #62A4FF;
    /* background-image: linear-gradient(to right,rgb(87, 157, 255, 80%), rgb(87, 157, 255, 80%)), url('../../assets/texture/background-texture.jpeg');
    background-size: cover;
    background-repeat: no-repeat; */
}

.titles {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
    padding: 10% 8% 10% 8%;
}

.titles .surtitle {
    margin: 0 0 45px 0;
    color: #353740;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.titles h1 {
    font-size: xxx-large;
    color: #f7f7f7;
    text-transform: uppercase;
    margin: 0 0 40px 0;
    word-wrap: break-word;
    font-family: 'Pinyon Script',cursive,'Montserrat',Helvetica,Arial,Lucida,sans-serif;
}

.titles h1 span {
    color: #353740;
    word-wrap: break-word;
}

.titles .description {
    font-weight: 500;
    font-size: 20px;
    margin: 0 0 50px 0;
    width: auto;
    color: #353740;
}

.buttonContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 0 0;
}

.buttonContainer a {
    text-decoration: none;
    color: #ffffff;
    border: #ffffff solid 4px;
    border-radius: 100px;
    letter-spacing: 2px;
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
    font-weight: 700;
    font-size: large;
    text-transform: uppercase;
    padding: 1em;
}

.heroImage {
    display: flex;
    width: 50%;
    background-image: url('../../../assets/header/papier_ancien.jpg');
    background-size: cover;
    /* background-position: center; */
    background-repeat: no-repeat;
}

@media (max-width: 981px){
    .heroImage {
        display: none;
    }

    .titlesContainer {
        width: 100%;
        justify-content: center;
        height: fit-content;
    }

    .titles {
        width: 80%;
    }
}

.form {
    padding-top: 110px;
    padding-bottom: 110px;
    background-color: #62A4FF;
}

.formTitlesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formTitles {
    width: 80%;
    text-align: center;
    margin-bottom: 2,75%;
}

.formTitles h3 {
    width: 100%;
    color: #333;
    padding-bottom: 10px;
    margin: 0;
    font-family: 'Pinyon Script',cursive,'Montserrat',Helvetica,Arial,Lucida,sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 42px;
    line-height: 1.3em;
    text-align: center;
}

.formTitles h3 span {
    color: #fff;
    vertical-align: baseline;
    font-size: 100%;
}

.paragraph {
    width: 80%;
    vertical-align: baseline;
    font-weight: 500;
    font-size: 100%;
    color: #353740;
    text-align: center;
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
    line-height: 1.8em;
    word-wrap: break-word;
}

.formInputsContainer {
    width: 100%;
    padding: 2% 0;
    display: flex;
    justify-content: center;
}

.formInputs {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formInputs form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1080px;
}

.formInputs form p {
    margin: 0 0 3% 0;
    width: 100%;
    display: flex;
}

.textArea {
    margin-bottom: 60px!important;
}

.formInputs form p label {
    display: none;
    position: relative;
}

.formInputs form p input {
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
    font-size: 16px;
    box-shadow: inset 0px -1px 0px 0px rgb(0 0 0 / 18%);
    background-color: rgba(255,255,255,0);
    width: 100%;
    border-width: 0;
    padding: 16px;
}

.formInputs form p input::placeholder {
    color: #353740;
}

.formInputs form textarea {
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
    font-size: 16px;
    box-shadow: inset 0px -1px 0px 0px rgb(0 0 0 / 18%);
    min-height: 150px;
    display: block;
    background-color: rgba(255,255,255,0);
    width: 100%;
    border-width: 0;
    border-radius: 0;
    padding: 16px;
}
.formInputs form textarea::placeholder {
    color: #353740;
}

.formButtonContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.formButtonContainer button {
    color: #ffffff;
    border-width: 5px!important;
    border-radius: 100px;
    letter-spacing: 2px;
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 0 0 18px;
    font-size: 20px;
    padding: 0.3em 1em;
    line-height: 1.7em;
    background-color: transparent;
    border: 2px solid;
}

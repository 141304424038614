.ContactContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Contact {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.TarifsRedaction {
    background-color: #2C3845;
    margin-bottom: 50px;
    color: whitesmoke;
}

.TarifsRedaction table {
    display: block;
}

.TarifsRedaction tr,
.TarifsRedaction td,
.TarifsRedaction tbody,
.TarifsRedaction tfoot {
    display: block;
}

.TarifsRedaction thead {
    display: none;
}

.TarifsRedaction tr {
    padding-bottom: 10px;
    background-color: #2C3845;
}

.TarifsRedaction td {
    padding: 10px 10px 0;
    text-align: center;
}

.TarifsRedaction td:before {
    /* content: attr(data-title); */
    color: #7a91aa;
    text-transform: uppercase;
    font-size: 1.4rem;
    padding-right: 10px;
    display: block;
}

.TarifsRedaction table {
    width: 100%;
}

.TarifsRedaction th {
    text-align: left;
    font-weight: 700;
}

.TarifsRedaction thead th {
    background-color: #202932;
    color: #fff;
    border: 1px solid #202932;
}

.TarifsRedaction tfoot th {
    display: block;
    padding: 10px;
    text-align: center;
    color: #b8c4d2;
}

.TarifsRedaction .button {
    line-height: 1;
    display: inline-block;
    font-size: 1.2rem;
    text-decoration: none;
    border-radius: 5px;
    color: #fff;
    padding: 8px;
    background-color: #0094c1;
}

.TarifsRedaction .details {
    padding-bottom: 20px;
    border-bottom: 1px solid #28333f;
}

.TarifsRedaction .details a {
    width: fit-content;
}

.TarifsRedaction .details:before {
    /* display: none; */
}

@media (min-width: 460px) {
    .TarifsRedaction td {
        text-align: left;
    }

    .TarifsRedaction td:before {
        display: inline-block;
        text-align: right;
        width: 140px;
    }

    .TarifsRedaction .details {
        padding-left: 160px;
    }
}

@media (min-width: 720px) {
    .TarifsRedaction table {
        display: table;
    }

    .TarifsRedaction tr {
        display: table-row;
    }

    .TarifsRedaction td,
    .TarifsRedaction th {
        display: table-cell;
    }

    .TarifsRedaction tbody {
        display: table-row-group;
    }

    .TarifsRedaction thead {
        display: table-header-group;
    }

    .TarifsRedaction tfoot {
        display: table-footer-group;
    }

    .TarifsRedaction td {
        border: 1px solid #28333f;
    }

    .TarifsRedaction td:before {
        display: none;
    }

    .TarifsRedaction td,
    .TarifsRedaction th {
        padding: 10px;
    }

    .TarifsRedaction tr:nth-child(2n+2) td {
        background-color: #242e39;
    }

    .TarifsRedaction tfoot th {
        display: table-cell;
    }

    .TarifsRedaction .details {
        padding: 10px;
    }
}

.footerContainer {
    padding: 15px 0 5px;
    
}

.footer {
    width: 80%;
    max-width: 1080px;
    margin: auto;
    color: #666;
    font-family: Open Sans,Arial,sans-serif;
    line-height: 1.7em;
    
    display: flex;
    justify-content: space-between;
}

.footer p {
    text-align: left;
    padding-bottom: 10px;
    margin: 0;
}

.footerRight {
    display: flex;
}

.footer ul {
    float: right;
    list-style: none;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}

.footer li {
    display: inline-block;
    margin-left: 20px;
}

.footer a {
    color: #666;
    text-decoration: underline;
}

.footer a img {
    width: 25px;
    height: 25px;
}

@media (max-width: 980px) {
    .footer {
        display: flex;
        flex-direction: column;
    }

    .footer li {
        padding-top: 20px;
    }

    .footer p {
        padding: 20px 0;
        text-align: center;
    }

    .footerRight {
        flex-direction: column-reverse;
        justify-content: center;
    }
}

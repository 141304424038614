.ServicesSection1 {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

h2 {
    margin: 0 0 2.75% 0;
    padding-bottom: 10px;
    font-family: 'Pinyon Script',cursive,'Montserrat',Helvetica,Arial,Lucida,sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 42px;
    line-height: 1.3em;
}

.tarifs {
    display: flex;
    flex-direction: column;
}

.ServicesSection1 p {
    margin: 0 0 8.696% 0;
    padding-bottom: 20px;
    text-align: left;
    color: #747d88;
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
    font-size: 100%;
    font-weight: 500;
    line-height: 1.8em;
    max-width: 700px;
    word-wrap: break-word;
}
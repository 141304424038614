.AboutSection1 {
    width: 80%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 5% 0;
}

.AboutSection1 .profile {
    width: 50%;
    height: auto;
    display: flex;
    justify-content: center;
}

.AboutSection1 .profile .profileCard {
    width: 50%;
    height: auto;
    margin: 20px;
}

.AboutSection1 .profile .profileCard #profilePicture {
    width: 80%;
    height: auto;
    border-radius: 10px;
}

.AboutSection1 .profile .profileCard .logoReseaux {
    width: 20%;
    height: auto;
}

.AboutSection1 .profile .profileCard h4 {
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
    font-weight: 700;
    font-size: 22px;
    color: #353740;
    line-height: 1.4em;
    padding-bottom: 10px;
    margin: 0;
    text-align: center;
}

.AboutSection1 .profile .profileCard p {
    color: #aaa;
    line-height: 1.7em;
    font-weight: 500;
    word-wrap: break-word;
    text-align: center;
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
    margin: 0;
}

.AboutSection1 .profile .profileCard .socialsLinksCard {
    display: flex;
    justify-content: center;
    align-items: center;
}

.AboutSection1 .profile .profileCard .socialsLinksCard a {
    font-size: 100%;
    position: relative;
    text-align: center;
    transition: color .3s ease 0s;
    color: #b2b2b2;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.AboutSection1 .profileDescriptionContainer {
    width: 50%;
    height: auto;
    display: flex;
    align-items: center;
}

.AboutSection1 .profileDescription h4 {
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
    font-weight: 700;
    font-size: 22px;
    color: #353740;
    line-height: 1.4em;
    padding-bottom: 10px;
    margin: 0;
    text-align: left;
}

.AboutSection1 .profileDescription p {
    text-align: left;
    font-weight: 500;
    font-size: 100%;
    color: #747d88;
    line-height: 1.8em;
    padding-bottom: 1em;
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
    word-wrap: break-word;
    margin: 0;
}

.AboutSection1 .profileDescription p a {
    text-decoration: underline;
    text-decoration-style: double;
    text-decoration-color: rgb(87, 157, 255);
    color: #353740;
}

@media (max-width: 768px) {
    .AboutSection1 {
        flex-direction: column;
        align-items: center;
    }

    .AboutSection1 .profile {
        margin-bottom: 20px;
        width: 100%;
    }

    .AboutSection1 .profileDescriptionContainer {
        width: 100%;
    }
}

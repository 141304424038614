.TarifsCorrection {
    background-color: #2C3845;
    margin-bottom: 50px;
    color: whitesmoke;
    border-radius: 30px;
}

.TarifsCorrection table {
    display: block;
    width: 100%;
    border-radius: 30px;
}

.TarifsCorrection tr,
.TarifsCorrection td,
.TarifsCorrection tbody,
.TarifsCorrection tfoot {
    display: block;
}

.TarifsCorrection thead {
    display: none;
}

.TarifsCorrection tr {
    padding-bottom: 10px;
    background-color: #2C3845;
}

.TarifsCorrection td {
    padding: 10px 10px 0;
    text-align: center;
}

.TarifsCorrection td:before {
    /* content: attr(data-title); */
    color: #7a91aa;
    text-transform: uppercase;
    font-size: 1.4rem;
    padding-right: 10px;
    display: block;
}

.TarifsCorrection th {
    text-align: left;
    font-weight: 700;
}

.TarifsCorrection thead th {
    background-color: #202932;
    color: #fff;
    border: 1px solid #202932;
    border-radius: 30px;
}

.TarifsCorrection tfoot th {
    display: block;
    padding: 10px;
    text-align: center;
    color: #b8c4d2;
}

.TarifsCorrection .button {
    line-height: 1;
    display: inline-block;
    font-size: 1.2rem;
    text-decoration: none;
    border-radius: 5px;
    color: #fff;
    padding: 8px;
    background-color: #0094c1;
}

.TarifsCorrection .details {
    padding-bottom: 20px;
    border-bottom: 1px solid #28333f;
}

.TarifsCorrection .details .button {
    width: fit-content;
}

.TarifsCorrection .details:before {
    display: none;
}

@media (min-width: 460px) {
    .TarifsCorrection td {
        text-align: left;
    }

    .TarifsCorrection td:before {
        display: inline-block;
        text-align: right;
        width: 140px;
    }

    .TarifsCorrection .details {
        padding-left: 160px;
    }
}

@media (min-width: 720px) {
    .TarifsCorrection table {
        display: table;
        border-radius: 30px;
    }

    .TarifsCorrection tr {
        display: table-row;
    }

    .TarifsCorrection td,
    .TarifsCorrection th {
        display: table-cell;
    }

    .TarifsCorrection tbody {
        display: table-row-group;
    }

    .TarifsCorrection thead {
        display: table-header-group;
    }

    .TarifsCorrection tfoot {
        display: table-footer-group;
    }

    .TarifsCorrection td {
        border: 1px solid #28333f;
    }

    .TarifsCorrection td:before {
        display: none;
    }

    .TarifsCorrection td,
    .TarifsCorrection th {
        padding: 10px;
    }

    .TarifsCorrection tr:nth-child(2n+2) td {
        background-color: #242e39;
    }

    .TarifsCorrection tfoot th {
        display: table-cell;
    }

    .TarifsCorrection .details {
        padding: 10px;
    }
}
  
.AboutPage {
    width: 100%;
}

.AboutPage .divspan {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.AboutPage span {
    width: 20%;
    height: 2px;
    background-color: #80808054;
    border-radius: 2px;
}

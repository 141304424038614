@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

nav {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    height: 86px;
    background-color: white;
    font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
    text-transform: uppercase;
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 0 0 30px rgba(7,51,84,0.17);
    z-index: 1;
}

.nav-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 90%;
    justify-content: space-between;
}

.logo {
    height: 75%;
    width: auto;
}

.logo a {
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #353740;
    height: 100%;
    width: auto;
}

.logo img {
    width: auto;
    height: 100%;
}

/* @font-face {
    font-family: 'DancingScript';
    src: local('DancingScript'),
        url('./fonts/DancingScript/static/DancingScript-Medium.ttf'),
        format('truetype');
} */

.logo a p {
    font-weight: 700;
    font-size: 1.5rem;
    margin-left: 10px;
    white-space: nowrap;
    font-family: 'DancingScript', 'Pinyon Script',cursive,'Montserrat',Helvetica,Arial,Lucida,sans-serif;
}

.menu {
    flex: 4;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.menu a {
    text-decoration: none;
    margin-left: 20px;
    color: black;
    font-weight: 500;
    font-size: large;
    white-space: nowrap;
}

.burgerContainer {
    display: flex;
    justify-content: flex-end;
}

.burgerContainer .burgerBtn {
    display: none;
    cursor: pointer;
}

.burgerContainer .burgerBtn .line1,
.burgerContainer .burgerBtn .line2,
.burgerContainer .burgerBtn .line3 {
    width: 25px;
    height: 3px;
    background-color: black;
    margin: 5px 0;
    margin-right: 0;
}

@media (max-width: 877px) {
    .menu {
        flex: 1;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        display: none;
        cursor: pointer;
    }

    .menu a {
        margin: 10px 0;
        width: 100%;
        text-align: center;
        color: black;
        font-weight: 500;
    }

    .menuOpen {
        transition: transform 0.5s ease-in-out;
        box-shadow: 0 4px 2px -2px gray;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 80px;
        left: 0;
        background-color: white;
        height: auto;
        width: 100vw;
        z-index: 1;
    }

    .burgerContainer .burgerBtn {
        display: block;
        flex: 1;
    }

    /* .burgerContainer .burgerBtnOpen {
        display: none;
    } */

    .burgerContainer .burgerBtnOpen .line1{
        transform: rotate(-45deg) translate(-5px, 6px);
    }

    .burgerContainer .burgerBtnOpen .line2 {
        opacity: 0;
    }

    .burgerContainer .burgerBtnOpen .line3 {
        transform: rotate(45deg) translate(-5px, -6px);
    }
}

.open a {
    display: block;
}

.Section2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section2ImgContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    background-image: linear-gradient(180deg,#ffffff 65%, #62A4FF 65%)!important;
    padding: 4% 0 2% 0;
}

.section2Img {
    display: flex;
    justify-content: center;
    width: 80%;
    height: auto;
    padding: 20px;
    box-shadow: 0px 2px 77px 0px rgb(0 0 0 / 30%);
    background-color: white;
    max-width: 900px;
}

.section2Img img {
    width: 100%;
    height: auto;
}

.section2ChiffresContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 60px;
    padding-bottom: 110px;
    background-color: #62A4FF;
}

.section2Chiffres {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    padding: 4% 0;
    margin-bottom: 40px;
    padding: 2% 0;
}

.section2Chiffres h2 {
    font-family: 'Pinyon Script',cursive,'Montserrat',Helvetica,Arial,Lucida,sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 42px;
    line-height: 1.3em;
    color: #333;
    padding-bottom: 10px;
}

.section2Chiffres p {
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
    font-weight: 500;
    font-size: 100%;
    line-height: 1.8em;
    margin: 0;
    padding: 0;
    max-width: 1080px;
    color: #353740
}

.statsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    padding: 0 0 60px 0;
    margin: 60px 0 0 0;
}

.stats {
    margin-bottom: 30px;
}

.stats h3,
.statsLast h3 {
    color: whitesmoke;
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
    font-size: 60px;
    font-weight: 700;
    margin: 0;
    padding: 0;
    line-height: 90px;
}

.stats p,
.statsLast p {
    color: #353740;
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.8em;
    margin: 0;
    padding: 0;
    max-width: 1080px;
    text-transform: uppercase;
}
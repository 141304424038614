.App {
  text-align: center;
}

.underNavbar {
  width: 100%;
  height: 106px;
}

footer {
  background-color: #222;
}
